// import logo from './logo.svg';
import './App.css';
import AutomationRoiCalculator from './components/AutomationRoiCalculator/AutomationRoiCalculator.js'

function App() {
  return (
    <div className="App">
      <AutomationRoiCalculator />
    </div>
  );
}

export default App;
