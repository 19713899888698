import React from 'react';
// import PropTypes from 'prop-types';
import './AutomationRoiCalculator.css';


const AutomationRoiCalculator = (props) => {
  const [calculated, setCalculated] = React.useState(false);
  const [taskFreq, setTaskFreq] = React.useState(1);
  const [taskWeekend, setTaskWeekend] = React.useState("No");
  const [taskHours, setTaskHours] = React.useState(1);
  const [taskValue, setTaskValue] = React.useState(50);
  const [taskYears, setTaskYears] = React.useState(5);
  const [taskBudget, setTaskBudget] = React.useState(5000);
  const [totalHours, setTotalHours] = React.useState(0);
  const [totalValue, setTotalValue] = React.useState(0);
  const [roi, setRoi] = React.useState(0);

  const handleCalculated = (event) => {
    setCalculated(event.target.value);
  }
  const handleTaskFreq = (event) => {
    setTaskFreq(event.target.value);
  }
  const handleTaskWeekend = (event) => {
    setTaskWeekend(event.target.value);
  }
  const handleTaskHours = (event) => {
    setTaskHours(event.target.value);
  }
  const handleTaskValue = (event) => {
    setTaskValue(event.target.value);
  }
  const handleTaskYears = (event) => {
    setTaskYears(event.target.value);
  }
  const handleTaskBudget = (event) => {
    setTaskBudget(event.target.value);
  }
  const handleTotalHours = (event) => {
    setTotalHours(event.target.value);
  }
  const handleTotalValue = (event) => {
    setTotalValue(event.target.value);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    let totalDays = 0;
    if (taskWeekend === "Yes") {
      totalDays = taskYears * 365.25;
    } else {
      totalDays = taskYears * 260.7145;
    }
    const totalHours = Math.round(totalDays * taskFreq * taskHours);
    setTotalHours(totalHours);
    const totalValue = Math.round(totalHours * taskValue);
    setTotalValue(totalValue);
    const roi = Math.round((totalValue / taskBudget) * 100);
    setRoi(roi);
    setCalculated(true);
  }

  const handleReset = (event) => {
    setCalculated(false);
  }


  if (calculated) {
    return (
      <div className="AutomationRoiCalculator">
        <div className="result">
          <h1>Automation ROI</h1>
          <p>Your task will cost a total of {totalHours} hours over the next {taskYears} years to be done manually.</p>
          <p>According to your estimation, this will cost you {totalValue}.</p>
          <p>If you spend your budget of {taskBudget} on automating this task, you will get a</p>
          <h2>{roi}% ROI</h2>
          <button className="btn btn-primary" onClick={handleReset}>Calculate another task</button>
        </div>
      </div>
    );
  }
  else {
    return (
      <div className="AutomationRoiCalculator" data-testid="AutomationRoiCalculator">
        <div>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="selectFreq">How often is the task done?</label>
              <select className="form-control" id="selectFreq" defaultValue={taskFreq} onChange={handleTaskFreq}>
                <option value="100">100 times a day</option>
                <option value="50">50 times a day</option>
                <option value="25">25 times a day</option>
                <option value="10">10 times a day</option>
                <option value="5">5 times a day</option>
                <option value="1">Once a day</option>
                <option value="0.2">Once a week</option>
                <option value="0.0327">Once a month</option>
                <option value="0.0109">Once a quarter</option>
                <option value="0.0027">Once a year</option>
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="selectWeekends">Is the task done on weekends?</label>
              <select className="form-control" id="selectWeekends" defaultValue={taskWeekend} onChange={handleTaskWeekend}>
                <option value="No">No</option>
                <option value="Yes">Yes</option>
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="selectHours">How much time does it take?</label>
              <select className="form-control" id="selectHours" defaultValue={taskHours} onChange={handleTaskHours}>
                <option value="0.0027">10 seconds</option>
                <option value="0.0166">1 minute</option>
                <option value="0.0833">5 minutes</option>
                <option value="0.25">15 minutes</option>
                <option value="0.5">30 minutes</option>
                <option value="1">1 hour</option>
                <option value="2">2 hours</option>
                <option value="4">4 hours</option>
                <option value="8">1 day (8 hours)</option>
                <option value="40">5 days</option>
                <option value="80">10 days</option>
                <option value="160">1 month</option>
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="selectCost">Cost per hour: {taskValue}</label>
              <input id="selectCost" className="form-control" type="range" min="10" max="1000" step="10" defaultValue={taskValue} onChange={handleTaskValue} />
            </div>
            <div className="form-group">
              <label htmlFor="selectCost">Years the task will be done: {taskYears}</label>
              <input id="selectCost" className="form-control" type="range" min="1" max="100" defaultValue={taskYears} onChange={handleTaskYears} />
            </div>
            <div className="form-group">
              <label htmlFor="selectCost">Automation budget: {taskBudget}</label>
              <input id="selectCost" className="form-control" type="range" min="1000" max="100000" step="1000" defaultValue={taskBudget} onChange={handleTaskBudget} />
            </div>
            <button type="submit" className="btn btn-primary">Calculate ROI</button>
          </form>
        </div>
      </div>
    );
  };
}

AutomationRoiCalculator.defaultProps = {};

export default AutomationRoiCalculator;
